<script>
export default {
  model: {
    prop: "modelData",
    event: "input",
  },
  props: ["modelData", "position"],
  data() {
    return {
      isSelected: false,
    };
  },
  methods: {
    selectRow() {
      this.isSelected = true;
      this.$emit("selected", this);
    },
  },
  computed: {},
};
</script>
<template>
  <div
    :ref="modelData.maintenanceId"
    @click="selectRow"
    :class="{
      handle: false,
    }"
    class="card draggable task-box dark-border-shadow mb-2 mt-1"
    :style="{
      border: isSelected ? '3px dashed #8fbce6' : '3px dashed transparent',
      background: modelData.maintenanceColorHexa,
    }"
  >
    <div class="card-body px-1 pt-1 pb-1">
      <div class="card-title px-1 pt-1 m-0">
        <div class="d-flex align-items-center">
          <div class="text-white">
            <span class="mr-1">#{{ position + 1 }}</span>
            <span>
              {{ modelData.maintenanceName }}
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row ml-1 text-white">
        <span class="ml-1">
          {{$t("general.start")}} : {{ $dayjs(modelData.startDate).format('YYYY-MM-DD HH:mm')  }}
        </span>
      </div>
    </div>
  </div>
</template>