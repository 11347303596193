<script>
export default {
  components: {},
  model: {
    prop: "thresholdsFilter",
  },
  props: ["thresholdsFilter"],
  data() {
    return {
      selectedThreshold: [],
      thresholds: [
        {
          id: "regular",
          name: this.$t("general.regular"),
          html:
            '<span class="badge badge-info" style="font-size: 1em;background-color: #34c38f;">' +
            this.$t("general.regular") +
            "ff</span>",
          htmlMultiselect:
            '<span class="badge badge-info my-1" style="font-size: 1em;background-color: #34c38f;">' +
            this.$t("general.regular") +
            '  <small> <i class="fas fa-times"></i></small></span>',
          htmlMultiselectNoX:
            '<span class="badge badge-info" style="font-size: 1em;background-color: #34c38f;">' +
            this.$t("general.regular") +
            "</span>",
        },
        {
          id: "attentionRequired",
          name: this.$t("general.attentionRequired"),
          html:
            '<span class="badge badge-info" style="font-size: 1em;background-color: #f1b44c;">' +
            this.$t("general.attentionRequired") +
            "</span>",
          htmlMultiselect:
            '<span class="badge badge-info my-1" style="font-size: 1em;background-color: #f1b44c;">' +
            this.$t("general.attentionRequired") +
            '  <small> <i class="fas fa-times"></i></small></span>',
          htmlMultiselectNoX:
            '<span class="badge badge-info" style="font-size: 1em;background-color: #f1b44c;">' +
            this.$t("general.attentionRequired") +
            "</span>",
        },
        {
          id: "urgent",
          name: this.$t("general.urgent"),
          html:
            '<span class="badge badge-info" style="font-size: 1em;background-color: #f46a6a;">' +
            this.$t("general.urgent") +
            "</span>",
          htmlMultiselect:
            '<span class="badge badge-info my-1" style="font-size: 1em;background-color: #f46a6a;">' +
            this.$t("general.urgent") +
            '  <small> <i class="fas fa-times"></i></small></span>',
          htmlMultiselectNoX:
            '<span class="badge badge-info" style="font-size: 1em;background-color: #f46a6a;">' +
            this.$t("general.urgent") +
            "</span>",
        },
        {
          id: "late",
          name: this.$t("general.late"),
          html:
            '<span class="badge badge-info" style="font-size: 1em;background-color: #343a40;">' +
            this.$t("general.late") +
            "</span>",
          htmlMultiselect:
            '<span class="badge badge-info my-1" style="font-size: 1em;background-color: #343a40;">' +
            this.$t("general.late") +
            '  <small> <i class="fas fa-times"></i></small></span>',
          htmlMultiselectNoX:
            '<span class="badge badge-info" style="font-size: 1em;background-color: #343a40;">' +
            this.$t("general.late") +
            "</span>",
        },
      ],
    };
  },
  watch: {
    selectedThreshold: {
      handler(value) {
        this.$emit(
          "input",
          value?.map((x) => x.id)
        );
      },
    },
  },
};
</script>
<template>
  <multiselect
    id="thresholdSelect"
    ref="multiselectThreshold"
    class="col-3"
    v-model="selectedThreshold"
    :options="thresholds"
    :label="'name'"
    track-by="id"
    :placeholder="$t('general.thresholds')"
    @remove="$refs.multiselectThreshold.deactivate()"
    :multiple="true"
    :show-labels="false"
    :hide-selected="true"
    :close-on-select="false"
    :clear-on-select="false"
    :searchable="false"
  >
    <template v-slot:noOptions>
      {{ $t("general.noResult") }}
    </template>
    <template slot="option" slot-scope="props">
      <div class="option__desc" v-html="props.option.htmlMultiselectNoX"></div>
    </template>
    <template slot="tag" slot-scope="{ option, remove }">
      <span
        class="custom__tag"
        v-html="option.htmlMultiselect"
        style="margin-right: 5px; cursor: pointer"
        @click="remove(option)"
      >
      </span>
    </template>
  </multiselect>
</template>
