<script>
/**
 * Dashboard Component
 */
let defaultTimerValue = 300;

export default {
  components: {},
  data() {
    return {
      defaultTimerValue: defaultTimerValue,
      userTimerValue: null,
      timerValue: defaultTimerValue,
      timerInterval: null,
      timerPaused: false,
      timeoutDebounce: null,
    };
  },
  created() {},
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
  mounted() {
    //decrease timer every second
    this.setTimerInterval();
  },
  methods: {
    pauseTimer() {
      this.timerPaused = true;

      clearInterval(this.timerInterval);
    },
    playTimer() {
      this.timerPaused = false;

      this.setTimerInterval();
    },
    refresh() {
      this.timerValue = this.userTimerValue
        ? this.userTimerValue
        : this.defaultTimerValue;
      this.$emit("refresh", { loadingOverlay: true });
    },
    focusTimerInput() {
      clearInterval(this.timerInterval);
    },
    blurTimerInput() {
      if (!this.timerPaused) {
        this.setTimerInterval();
      }
    },
    setTimerInterval() {
      this.timerInterval = setInterval(() => {
        if (this.timerValue <= 0) {
          this.timerValue = this.userTimerValue
            ? this.userTimerValue
            : this.defaultTimerValue;

          //reload
          this.$emit("refresh", {
            loadingOverlay: false,
          });
        } else {
          this.timerValue -= 1;
        }
      }, 1000);
    },
  },
  computed: {
    timeLeft: {
      get: function () {
        return this.timerValue;
      },
      set: function (value) {
        //debounce sur le input user
        if (this.timeoutDebounce != null) {
          clearTimeout(this.timeoutDebounce);
        }

        this.timeoutDebounce = setTimeout(() => {
          this.userTimerValue = value;
          this.timerValue = value;
        }, 500);
      },
    },
  },
};
</script>

<template>
  <div
    class="card-title mb-0 pt-3 pb-2 pr-3 pl-3"
    style="border-top-left-radius: 0.25rem; border-top-right-radius: 0.25rem;background:#FFC25D;"
  >
    <div class="mb-2" style="display: flex; align-items: center">
      <div
        class="mr-3"
        style="
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 1.1em;
          color: white;
        "
      >
        <div>
          {{ $t("general.maintenances") }}
        </div>
      </div>
      <div
        class="refreshBox"
        style="display: flex; align-items: center; margin-left: auto"
      >
        <i
          @click="refresh"
          class="mdi mdi-refresh mr-2"
          style="cursor: pointer; font-size: 1.3em"
        ></i>
        <i
          v-show="timerPaused"
          @click="playTimer"
          class="mdi mdi-play mr-2"
          style="cursor: pointer; font-size: 1.3em"
        ></i>
        <i
          v-show="!timerPaused"
          @click="pauseTimer"
          class="mdi mdi-pause mr-2"
          style="cursor: pointer; font-size: 1.3em"
        ></i>
      </div>
    </div>
  </div>
</template>
