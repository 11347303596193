<script>
import CalendarApiService from "@/services/calendarService";
import draggable from "vuedraggable";
import OngoingMaintenancesColumnHeader from "./ongoing-maintenances-column-header";
import OngoingMaintenancesRow from "./ongoing-maintenances-row";

/**
 * Dashboard Component
 */
export default {
  props: [],
  components: {
    draggable,
    OngoingMaintenancesColumnHeader,
    OngoingMaintenancesRow,
  },
  data() {
    return {
      isLoading: false,
      isLoadingOverlay: false,
      maintenances: [],
      selectedRow: null,
      page: 1,
    };
  },
  watch: {},
  created() {
    this.$bus.$on("locale-updated", this.localeUpdated);
    this.loadData();
  },
  beforeDestroy() {
    this.$bus.$off("locale-updated", this.localeUpdated);
  },
  mounted() {},
  computed: {
    isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    },
  },
  methods: {
    loadInitialData(loadingOverlay) {
      this.isLoadingOverlay = loadingOverlay ?? false;
      this.page = 1;
      this.loadData(loadingOverlay);
    },
    loadData(loadingOverlay) {
      this.isLoadingOverlay = loadingOverlay ?? false;
      this.page = 1;
      return CalendarApiService.getOngoingMaintenances(this.page)
        .then((response) => {
          this.maintenances = response.data;
        })
        .finally(() => {
          this.isLoadingOverlay = false;
          this.isLoading = false;
        });
    },
    loadMoreData() {
      this.page++;
      this.loadData();
    },
    localeUpdated() {
      this.$forceUpdate();
    },
    infiniteScroll(el) {
      let innerBottom =
        el.srcElement.children[0].getBoundingClientRect().bottom;
      let outerBottom = el.srcElement.getBoundingClientRect().bottom;

      if (innerBottom - 1 < outerBottom && this.isLoading == false) {
        this.isLoading = true;
        this.loadMoreData();
      }
    },
    rowSelected(row) {
      if (this.selectedRow != null && this.selectedRow != row) {
        this.selectedRow.isSelected = false;
      }
      this.selectedRow = row;
    },
    internalRowSelected(row) {
      if (this.selectedRow == row || row.modelData.isMaintenance) {
        this.$emit("clear-selected-row", row.modelData);
      } else {
        this.rowSelected(row);
        this.$emit("row-selected", row.modelData);
      }
    },
  },
};
</script>

<template>
  <div
    class="col progressPriotizerBox"
    style="
      min-width: 290px;
      margin: 0 5px;
      flex: 1 1 1px;
      min-height: 0;
      display: flex;
      flex-direction: column;
    "
  >
    <div style="flex: 1; min-height: 0; display: flex">
      <div
        class="card mb-2"
        style="
          padding: 0;
          flex: 1;
          min-height: 0;
          background: #0000000d none repeat scroll 0% 0%;
        "
      >
        <div
          class="card-body"
          style="
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 0;
          "
        >
          <OngoingMaintenancesColumnHeader
            ref="header"
            @refresh="loadData(true)"
          />
          <loading
            :active.sync="isLoadingOverlay"
            :can-cancel="false"
            :opacity="0.3"
            color="#f1b44c"
            :width="40"
            :height="40"
            loader="spinner"
            :is-full-page="false"
          >
          </loading>
          <!-- Remplacer le div par simplebar pour avoir un pretty scroll bar -->
          <!-- simplebar ne permet pas de scroll pendant quon drag un element -->
          <div
            ref="stepBox"
            @scroll="infiniteScroll($event)"
            class="mb-2 mt-2"
            style="
              scrollbar-width: thin;
              scrollbar-color: #dee0e3 transparent;
              overflow-y: auto;
              max-height: 100%;
              padding-right: 3px;
              margin: 0px 5px;
              flex: 1;
              min-height: 0;
            "
          >
            <draggable
              handle=".handle"
              v-model="maintenances"
              tag="div"
              :disabled="isTouchDevice"
              style="position: relative"
            >
              <OngoingMaintenancesRow
                v-for="(row, index) in maintenances"
                v-model="maintenances[index]"
                :key="index"
                :position="index"
                @selected="internalRowSelected"
                ref="rows"
              />
              <div style="position: relative; width: 100%; height: 100px">
                <loading
                  :active.sync="isLoading"
                  :can-cancel="false"
                  :opacity="0"
                  color="#f1b44c"
                  :width="40"
                  :height="40"
                  loader="spinner"
                  :is-full-page="false"
                >
                </loading>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>