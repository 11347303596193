<script>
import CalendarApiService from "@/services/calendarService";
import PrioritizationService from "@/services/prioritization-service";
import UserConfigMixin from "@/mixins/userConfigMixin";
export default {
  mixins: [UserConfigMixin],
  model: {
    prop: "progressPrioritizations",
    event: "input",
  },
  props: ["progressPrioritizations"],
  components: {},
  data() {
    return {
      progressPrioritizationList: [],
      ongoingMaintenancesVisible: false,
    };
  },
  created() {
    this.loadData();
    this.$emit("toggle-ongoingmaintenances", this.ongoingMaintenancesVisible);
  },
  methods: {
    loadData() {
      var localProgressPrioritizationList = [];
      var localProgressPrioritizations = [];
      let loadingProgressPrioritizations =
        CalendarApiService.getProgressSchedulingList().then((response) => {
          for (let p of response.data.data) {
            if (this.userCanSeeProgress(p.progress)) {
              localProgressPrioritizationList.push(p);
              this.$set(p, "checked", false);
              this.$set(p, "merged", false);
            }
          }

          //auto check selon selection user
          let selectedProgressPrioritizationIdsList = localStorage
            .getItem("selected_progress_prioritizations")
            ?.split(",");
          if (
            selectedProgressPrioritizationIdsList != null &&
            selectedProgressPrioritizationIdsList != ""
          ) {
            for (let selectedProgressId of selectedProgressPrioritizationIdsList) {
              //find progress
              let progressPrioritization = localProgressPrioritizationList.find(
                (p) => {
                  return !p.merged & (p.id == selectedProgressId);
                }
              );
              if (progressPrioritization != undefined) {
                //set checked
                progressPrioritization.checked = true;
                //load data
                localProgressPrioritizations.push(progressPrioritization);
              }
            }
          }
        });

      let loadingMergedProgressPrioritizations =
        CalendarApiService.getMergedProgressSchedulingUserList().then(
          (response) => {
            for (let smp of response.data.data) {
              localProgressPrioritizationList.push(smp);
              this.$set(smp, "checked", false);
              this.$set(smp, "merged", true);
            }

            //auto check selon selection user
            let selectedMergeProgressPrioritizationIdsList = localStorage
              .getItem("selected_merge_progress_prioritizations")
              ?.split(",");
            if (
              selectedMergeProgressPrioritizationIdsList != null &&
              selectedMergeProgressPrioritizationIdsList != ""
            ) {
              for (let selectedMergeProgressId of selectedMergeProgressPrioritizationIdsList) {
                //find merge progress
                let mergeProgressPrioritization =
                  localProgressPrioritizationList.find((mp) => {
                    return mp.merged && mp.id == selectedMergeProgressId;
                  });
                if (mergeProgressPrioritization != undefined) {
                  mergeProgressPrioritization.checked = true;
                  //load data
                  localProgressPrioritizations.push(
                    mergeProgressPrioritization
                  );
                }
              }
            }
          }
        );

      Promise.all([
        loadingProgressPrioritizations,
        loadingMergedProgressPrioritizations,
      ]).then(() => {
        this.progressPrioritizationList.splice(
          0,
          this.progressPrioritizationList.length
        );
        this.progressPrioritizations.splice(
          0,
          this.progressPrioritizations.length
        );
        localProgressPrioritizationList.sort(
          PrioritizationService.getSortPriorizationFct()
        );
        localProgressPrioritizations.sort(
          PrioritizationService.getSortPriorizationFct()
        );
        this.progressPrioritizationList.push(
          ...localProgressPrioritizationList
        );
        this.progressPrioritizations.push(...localProgressPrioritizations);
        let storageOngoingMaintenancesVisible = localStorage.getItem(
          "ongoing_maintenances_visible"
        );
        if (
          storageOngoingMaintenancesVisible != null &&
          storageOngoingMaintenancesVisible != ""
        ) {
          this.ongoingMaintenancesVisible = storageOngoingMaintenancesVisible;
        } else {
          this.ongoingMaintenancesVisible = false;
        }
        this.$emit("loading", false);
      });
    },
    checkPrioritization(prioritization) {
      let indexOf = this.progressPrioritizations.indexOf(prioritization);
      if (indexOf === -1) {
        this.progressPrioritizations.push(prioritization);
      } else {
        this.progressPrioritizations.splice(indexOf, 1);
      }
      if (prioritization.merged) {
        this.saveUserSelectedMergeProgress();
      } else {
        this.saveUserSelectedProgress();
      }
      this.progressPrioritizations.sort(
        PrioritizationService.getSortPriorizationFct()
      );
    },
    checkOngoingMaintenances() {
      localStorage.setItem("ongoing_maintenances_visible", this.ongoingMaintenancesVisible);
      this.$emit('toggle-ongoingmaintenances', this.ongoingMaintenancesVisible);
    },
    saveUserSelectedProgress() {
      var selectedProgress = this.progressPrioritizationList.filter(
        (p) => p.checked && !p.merged
      );
      var idsList = selectedProgress.map((p) => {
        return p.id;
      });
      localStorage.setItem("selected_progress_prioritizations", idsList);
    },
    saveUserSelectedMergeProgress() {
      var selectedMergeProgress = this.progressPrioritizationList.filter(
        (mp) => mp.checked && mp.merged
      );
      var idsList = selectedMergeProgress.map((p) => {
        return p.id;
      });
      localStorage.setItem("selected_merge_progress_prioritizations", idsList);
    },
  },
  computed: {},
};
</script>
<template>
  <b-dropdown
    id="cogDropdown"
    right
    variant="primary"
    :text="$t('general.columns')"
  >
    <template #button-content style="padding: 0">
      <i style="font-size: 1.6em" class="bx bx-cog"></i>
    </template>
    <b-dropdown-form
      v-for="(p, index) in progressPrioritizationList"
      :key="'progress' + index"
    >
      <b-form-checkbox
        v-if="!p.merged"
        switch
        class="mr-3"
        size="md"
        v-model="p.checked"
        @input="checkPrioritization(p)"
        >{{ p.progress.name }}
      </b-form-checkbox>
      <b-form-checkbox
        v-if="p.merged"
        switch
        class="mr-3"
        size="md"
        v-model="p.checked"
        @input="checkPrioritization(p)"
        ><i class="mr-1 mdi mdi-merge"></i>{{ p.name }}
      </b-form-checkbox>
    </b-dropdown-form>
    <b-dropdown-form>
      <b-form-checkbox
        switch
        class="mr-3"
        size="md"
        v-model="ongoingMaintenancesVisible"
        @input="checkOngoingMaintenances"
        >{{ $t("general.ongoingmaintenances") }}
      </b-form-checkbox>
    </b-dropdown-form>
  </b-dropdown>
</template>