<script>
export default {
    mixins: [],
    model: {
        prop: "stepFlashingDisplayNames",
        event: "input",
    },
    props: ["stepFlashingDisplayNames"],
    components: {},
    data() {
        return {
            isCompressedMode: false
        };
    },
    created() { },
    methods: {
        refreshIsCompressedMode() {
            this.isCompressedMode = false;
            setTimeout(() => {
                var divBadges = this.$refs.flashingDisplayBadges;
                if (divBadges) {
                    var childWidth = 0;
                    for (var childBadge of divBadges.children) {
                        childWidth += childBadge.offsetWidth;
                    }
                    if (divBadges.offsetWidth <= childWidth) {
                        this.isCompressedMode = true;
                    }
                }
            }, 100)

        },
    },
    computed: {
        parentWidth() {
            return this.$refs.flashingDisplayBadges.offsetWidth;
        },
        childsWidth() {
            var divBadges = this.$refs.flashingDisplayBadges;
            var childWidth = 0;
            for (var childBadge of divBadges.children) {
                childWidth += childBadge.offsetWidth;
            }
            return childWidth;
        }
    },
    mounted() {
        this.refreshIsCompressedMode();
    },
    beforeDestroy: function () {
    },
};
</script>
<template>
    <div>
        <div :class="{ 'd-flex-inline': !isCompressedMode, 'd-none': isCompressedMode, 'flex-nowrap': true }"
            ref="flashingDisplayBadges">
            <b-badge tag="div" v-for="(fDp, index) in stepFlashingDisplayNames" :key="index"
                class="mx-1 mb-1 py-1 blinkBG-no-border" :style="{ background: fDp.colorHexa, border: 0 }">{{ fDp.name
                }}</b-badge>
        </div>
        <div :class="{ 'd-none': !isCompressedMode, 'd-flex': isCompressedMode, 'flex-nowrap': true }">
            <div v-for="(fDp, index) in stepFlashingDisplayNames" :key="index" class="blinkBG-no-border progress-pill mx-1"
                :style="{ 'background-color': fDp.colorHexa }">&nbsp;
            </div>
        </div>
    </div>
</template>
<style scoped>
.progress-pill {
    z-index: 9998;
    border-radius: 3px;
    width: 10px;
}
</style>
