<script>
import UserConfigMixin from "@/mixins/userConfigMixin";
import ThresholdService from "@/services/thresholdservice";
import FlashingDisplayBadgeList from "./flashing-display-badge-list";

export default {
  components: { FlashingDisplayBadgeList },
  model: {
    prop: "modelData",
    event: "input",
  },
  mixins: [UserConfigMixin],
  props: ["modelData", "position", "merged", "thresholdsConfig"],
  data() {
    return {
      showInfo: false,
      isSelected: false,
    };
  },
  mounted() {},
  methods: {
    selectRow() {
      this.isSelected = true;
      this.$emit("selected", this);
    },
    stopProgressDependencyWarning() {
      this.modelData.isFlashingDisplay = false;
    },
    openNote() {
      this.selectRow();
      this.$emit("note-opened", this.modelData);
    },
    openHide() {
      this.selectRow();
      this.$emit("hide", this.modelData);
    },
    unlockPosition() {
      this.modelData.locked = false;
      this.$emit("unlock", this.modelData, this.position);
    },
    lockPosition() {
      this.$emit("lock", this.position, this.modelData);
    },
    setShowInfo(value) {
      this.showInfo = value;
    },
    resizeUpdate() {
      this.$refs.stepFlashingDisplayNames?.refreshIsCompressedMode();
    },
    getBusinessDays(startDate, endDate) {
      // Copy dates so we don't modify the original ones
      var start = new Date(startDate);
      var end = new Date(endDate);

      // Swap dates if start date is greater than end date
      if (start > end) {
        var temp = start;
        start = end;
        end = temp;
      }

      var count = 0;
      var dayMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

      // Loop through each day and increment count if it's not a weekend
      while (start <= end) {
        var day = start.getDay();
        if (day !== 0 && day !== 6) {
          // 0 = Sunday, 6 = Saturday
          count++;
        }
        start.setTime(start.getTime() + dayMilliseconds); // Move to the next day
      }

      return count * -1;
    },
  },
  computed: {
    commentTitle() {
      let commentTitle = "";
      if (this.modelData.eventComment !== null) {
        commentTitle +=
          "<label>" +
          this.$t("general.comment") +
          ":</label><div>" +
          this.modelData.eventComment +
          "</div>";
      }
      if(this.modelData.eventComment !== null && this.modelData.workOrder.comment !== null) {
        commentTitle += "<br/>";
      }
      if (this.modelData.workOrder.comment !== null) {
        commentTitle +=
          "<label>" +
          this.$t("general.workOrderComment") +
          ":</label><div>" +
          this.modelData.workOrder.comment +
          "</div>";
      }
      if (commentTitle === "") {
        return null;
      }
      return commentTitle;
    },
    idPublic() {
      return this.modelData.workOrder.idPublic;
    },
    thresholdColor() {
      return ThresholdService.getThresholdColor(
        this.modelData,
        this.thresholdsConfig
      );
    },
    bgColorClass() {
      var className = "redBg";
      let percentCompleted =
        this.modelData.workOrderProgress.percentCompleted * 100;
      if (percentCompleted == 0) {
        className = "redBg";
      } else if (percentCompleted > 0 && percentCompleted < 100) {
        className = "yellowBg";
      } else if (percentCompleted == 100) {
        className = "greenBg";
      }

      return className + " ml-1 align-middle";
    },
    dateForProgressReferenceDate() {
      let returnDate = null;

      let progress = this.modelData.workOrderProgress.progress;
      let workOrder = this.modelData.workOrder;

      let progressReferenceDate = progress.backSchedulingReferenceDate;

      if (progressReferenceDate == "Installation") {
        returnDate = this.$t("general." + "installation") + " ";
        returnDate +=
          workOrder.installationDate == null
            ? this.$dayjs().format("L")
            : this.$dayjs(workOrder.installationDate).format("L");
      } else if (progressReferenceDate == "Delivery") {
        returnDate = this.$t("general." + "delivery") + " ";
        returnDate +=
          workOrder.deliveryDate == null
            ? this.$dayjs().format("L")
            : this.$dayjs(workOrder.deliveryDate).format("L");
      } else if (progressReferenceDate == "Production") {
        returnDate = this.$t("general." + "production") + " ";
        returnDate +=
          workOrder.productionDate == null
            ? this.$dayjs().format("L")
            : this.$dayjs(workOrder.productionDate).format("L");
      }

      return returnDate;
    },
  },
};
</script>
<template>
  <div>
    <div
      v-if="modelData.isMaintenance"
      @click="selectRow"
      :class="{
        handle: false,
      }"
      class="card draggable task-box dark-border-shadow mb-2 mt-1"
      :style="{
        border: '3px dashed transparent',
        background: modelData.maintenanceColor,
      }"
    >
      <div class="card-body px-1 py-1 pb-0">
        <div class="row">
          <div class="col">
            <div class="card-title p-1 m-0 flex-row">
              <div class="d-flex align-items-center">
                <div class="text-white">
                  <span class="mr-1">#{{ position + 1 }}</span>
                  <span>
                    {{ modelData.maintenanceName }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-row ml-1 text-white"
              v-if="modelData.isCurrentEntry"
            >
              <span class="ml-1">
                {{ $t("general.start") }} :
                {{ $dayjs(modelData.startDate).format("YYYY-MM-DD HH:mm") }}
              </span>
            </div>
          </div>
          <div class="col-auto justify-content-center align-items-center">
            <i class="mdi mdi-hammer-wrench mdi-36px text-white"></i>
          </div>
        </div>
        <div class="d-flex flex-row ml-1 text-white align-items-center">
          <div class="pl-0">
            <span class="ml-1">
              {{
                $t(
                  "general." +
                    (modelData.type != null ? modelData.type : "workOrder")
                )
              }}
              :
            </span>
          </div>
          <div class="col">
            <b-progress :max="modelData.interval" class="">
              <b-progress-bar
                :value="modelData.accumulatedValue"
                variant="success"
                show-value
              ></b-progress-bar>
            </b-progress>
          </div>
          / {{ modelData.interval }}
        </div>
      </div>
    </div>
    <div
      v-if="!modelData.isMaintenance"
      :ref="modelData.workOrder.idPublic"
      @click="selectRow"
      :class="{
        handle: userIsAdmin,
        blinkBG: modelData.isFlashingDisplay,
      }"
      class="card draggable task-box dark-border-shadow mb-2 mt-1"
      :style="{
        border: isSelected ? '3px dashed #8fbce6' : '3px dashed transparent',
        background: isSelected ? 'aliceblue' : '',
      }"
    >
      <div class="card-body px-1 pt-1 pb-0">
        <div class="card-title px-1 pt-1 pb-0 m-0">
          <div class="d-flex align-items-center">
            <div>
              <span class="mr-1">#{{ position + 1 }}</span>
              <span :id="modelData.uniqueId + modelData.workOrder.idPublic">
                {{ modelData.workOrder.name }}
              </span>
              <b-tooltip
                variant="light"
                class="woTooltip"
                :target="modelData.uniqueId + modelData.workOrder.idPublic"
                title="workorder"
                triggers="hover"
              >
                {{ modelData.workOrder.name }}
              </b-tooltip>
            </div>
            <b-badge
              v-if="merged == true"
              class="ml-2"
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              "
              :style="{
                background: modelData.workOrderProgress.progress.colorHexa,
              }"
              >{{ modelData.workOrderProgress.progress.name }}</b-badge
            >
            <div style="margin-left: auto; display: flex; align-items: center">
              <i
                @click.stop="showInfo = !showInfo"
                style="cursor: pointer; font-size: 1.7em"
                class="ml-2"
                v-bind:class="{
                  'fas fa-angle-up': showInfo,
                  'fas fa-angle-down': !showInfo,
                }"
              ></i>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-start ml-2">
          <div>
            <span>{{ $t("general." + modelData.thresholdDisplayType) }}: </span>
            <b-badge
              class="ml-1 align-middle"
              :style="{ background: thresholdColor }"
            >
              {{
                parseFloat(
                  modelData.thresholdDisplayValue.toFixed(2)
                ).toLocaleString("en")
              }}
            </b-badge>
          </div>
          <div v-show="showInfo">
            <div class="d-flex flex-column justify-content-start">
              <div>
                <span
                  >{{ $t("general." + modelData.attributeDisplay) }}:
                  {{ modelData.attributeDisplayValue }}</span
                >
              </div>

              <div class="mb-1">
                <p class="text-muted mb-1">
                  <span>{{ $t("general.progress") }}:</span>
                  <b-badge :class="bgColorClass"
                    >{{
                      (
                        modelData.workOrderProgress.percentCompleted * 100
                      ).toFixed(0)
                    }}%</b-badge
                  >
                </p>
              </div>

              <div>
                <b-badge class="mr-1">{{
                  dateForProgressReferenceDate
                }}</b-badge>
                <b-badge
                  >{{
                    (modelData.workOrder.percentCompleted * 100).toFixed(0)
                  }}%</b-badge
                >
              </div>

              <div>
                <b-badge class="mr-1">
                  {{ $t("general.operationDate") }}
                </b-badge>
                <b-badge>
                  {{
                    modelData.operationDate == null
                      ? "NA"
                      : parseFloat(
                          modelData.daysUntilOperation.toFixed(2)
                        ).toLocaleString("en")
                  }}
                </b-badge>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex flex-row align-items-center ml-2">
            <div v-show="modelData.locked">
              <i
                style="font-size: 1.4em; color: #f46a6a"
                class="mdi mdi-lock"
              ></i>
            </div>
            <div
              style="
                width: 30px;
                height: 15.5px;
                border-radius: 4px;
                border: 1px solid #556ee6;
                padding: 2px;
                font-size: 0.8em;
                font-weight: bold;
                color: white;
                background-color: white;
              "
              class="mx-1 d-flex justify-content-center align-items-center"
              v-if="modelData.workOrderProgress.status == 'Started'"
            >
              <i style="color: #556ee6" class="mdi mdi-clock-start"></i>
            </div>
            <div v-if="modelData.isFlashingDisplay">
              <i
                @click.stop="stopProgressDependencyWarning"
                style="cursor: pointer"
                class="fas fa-exclamation-triangle text-danger"
              ></i>
            </div>
            <FlashingDisplayBadgeList
              @click.native.stop="stopProgressDependencyWarning"
              style="cursor: pointer"
              v-if="modelData.isFlashingDisplay"
              v-model="modelData.stepFlashingDisplayNames"
              ref="stepFlashingDisplayNames"
            />
            <div v-if="modelData.workOrder.hasDamaged">
              <i
                class="mdi mdi-18px mdi-alert-box text-warning align-middle"
              ></i>
            </div>
            <div v-if="modelData.workOrder.hasBackOrder" class="">
              <i
                class="mdi mdi-18px mdi-alert-box text-danger align-middle"
              ></i>
            </div>
            <div v-if="commentTitle">
              <i
                class="bx bx-xs bxs-comment-dots align-middle"
                v-b-tooltip.hover.html="commentTitle"
              ></i>
            </div>
          </div>
          <div class="d-flex">
            <div role="button">
              <i
                @click.stop="openNote"
                style="
                  color: rgb(189, 193, 198);
                  font-size: 1.4rem;
                  top: -1.5px;
                  position: relative;
                "
                :class="{ 'text-success': modelData.note, btn: true }"
                class="fas fa-sticky-note"
              ></i>
            </div>
            <b-dropdown
              v-if="userIsAdmin"
              class=""
              id="dropdown-2"
              right
              variant="none"
              size="sm"
            >
              <template #button-content>
                <i
                  title="Edit"
                  class="bx bxs-edit"
                  style="font-size: 1.6rem; color: rgb(189, 193, 198)"
                ></i>
              </template>
              <div class="d-flex justify-content-center">
                <i
                  @click="openHide"
                  role="button"
                  style="font-size: 2em"
                  class="bx bxs-hide text-danger"
                ></i>
              </div>
              <div class="d-flex justify-content-center">
                <i
                  @click.stop="unlockPosition"
                  v-show="modelData.locked"
                  role="button"
                  style="font-size: 2em; color: #f46a6a"
                  class="mdi mdi-lock"
                ></i>
              </div>
              <div class="d-flex justify-content-center">
                <i
                  v-if="userIsAdmin"
                  @click.stop="lockPosition"
                  v-show="!modelData.locked"
                  role="button"
                  style="font-size: 2em; color: rgb(189, 193, 198)"
                  class="mdi mdi-lock-open"
                ></i>
              </div>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>