export default {
    getSortPriorizationFct() {
        return (a, b) => {
            return a.merged && b.merged
                ? 0 :
                a.merged && !b.merged
                    ? 1
                    : !a.merged && b.merged
                        ? -1
                        : a.progress.order > b.progress.order
                            ? 1
                            : a.progress.order < b.progress.order
                                ? -1
                                : 0;
        };
    },
}