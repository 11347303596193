<script>
import UserConfigMixin from "@/mixins/userConfigMixin";
/**
 * Dashboard Component
 */
export default {
  props: {},
  components: {},
  mixins: [UserConfigMixin],
  data() {
    return {
      show: false,
      event: null,
      note: null,
      confirm: null,
      close: null,
    };
  },
  created() {},
  beforeDestroy() {},
  mounted() {},
  computed: {
    documentation_event() {
      return this.event == null ? null : {
        id: this.event.workOrder.name,
        title: this.event.workOrder.name,
        userCreated: false
      };
    },
    titleModal() {
      return this.event == null ? "" : this.event.workOrder.name;
    },
    documentation_options() {
      return {
        folders: this.userDocumentationFolders
      };
    },
  },
  methods: {
    showModal(event) {
      this.event = event;
      this.note = event.note;
      this.show = true;
      return new Promise((resolve, reject) => {
        this.confirm = () => {
          resolve(this.note);
        };
        this.close = () => {
          reject();
        };
      }).finally(() => {
        (this.event = null, this.note = null), (this.show = false);
      });
    },
  },
};
</script>

<template>
  <b-modal
    v-model="show"
    id="noteModal"
    :title="titleModal"
    style="position: relative"
    size="lg"
  >
    <label>{{ $t("general.note") }}</label>
    <b-form-textarea
      v-model="note"
      :placeholder="$t('general.enterNote')"
      rows="8"
    ></b-form-textarea>
    <div>
      <documentation-component
        class="mt-4"
        :options="documentation_options"
        v-model="documentation_event"
        v-show="userCanSeeAnyDocumentation"
      />
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="success" class="float-right" @click="confirm">
          {{ $t("general.save") }}
        </b-button>
        <b-button class="mr-2 float-right" @click="close">
          {{ $t("general.close") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
