<script>
import appConfig from "@/app.config";
import WorkOrderProgressPrioritizationBoard from "./components/workorder-progress-prioritization-board";
import WorkOrderSearchInput from "./components/workorder-search-input";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "maestro",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    WorkOrderProgressPrioritizationBoard,
    WorkOrderSearchInput,
  },
  data() {
    return {
      title: "maestro",
      selectedWorkOrder: null,
    };
  },
  created() {},
  beforeDestroy() {},
  mounted() {},
  methods: {
    workorderSelected(data){
      this.$refs.prioritizationBoard.searchWorkOrder(data);
    }
  },
};
</script>

<template>
  <Layout>
    <div
      style="
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-bottom: -60px;
      "
    >
      <WorkOrderSearchInput @workorder-selected="workorderSelected" />
      <WorkOrderProgressPrioritizationBoard ref="prioritizationBoard" />
    </div>
  </Layout>
</template>