<script>
import CalendarApiService from "@/services/calendarService";
import CompleteStepsBeforeModal from "@/router/views/modals/completeStepsBeforeModal";
import ProgressPrioritizationColumn from "./components/progress-prioritization-column";
import OngoingMaintenancesColumn from "./components/ongoing-maintenances-column";
import NoteModal from "./components/note-modal";
import LockPositionSelectorModal from "./components/lock-position-selector-modal";
import HideRowConfirmationModal from "./components/hide-row-confirmation-modal";
import ConfirmModal from "../../../../../components/confirm-modal.vue";
import ProgressPrioritizationSelector from "./components/progress-prioritization-selector";
import ThresholdFilter from "./components/threshold-filter";

/**
 * Dashboard Component
 */
export default {
  components: {
    ProgressPrioritizationColumn,
    ProgressPrioritizationSelector,
    CompleteStepsBeforeModal,
    ThresholdFilter,
    NoteModal,
    LockPositionSelectorModal,
    HideRowConfirmationModal,
    OngoingMaintenancesColumn,
    ConfirmModal
  },
  data() {
    return {
      progressPrioritizations: [],
      isLoading: false,
      thresholdsConfig: null,
      actionMode: "",
      showInfo: false,
      thresholdsFilter: [],
      ongoingMaintenancesVisible: undefined,
    };
  },
  computed: {},
  created() {
    this.isLoading = true;
    let t1 = CalendarApiService.getSchedulingThresholdsConfig().then(
      (response) => {
        this.thresholdsConfig = response.data.data;
      }
    );

    Promise.all([t1]).then(() => {
      this.isLoading = false;
    });
  },
  beforeCreate() { },
  beforeDestroy() { },
  mounted() { },
  methods: {
    setOngoingMaintenanceVisibility(visibility) {
      this.ongoingMaintenancesVisible = visibility;
    },
    changeInfo() {
      this.$refs.progressPrioritizationColumns?.forEach((x) =>
        x.setShowInfo(this.showInfo)
      );
    },
    searchWorkOrder(workOrder) {
      this.$refs.progressPrioritizationColumns?.forEach((x) =>
        x.externalRowSelected(workOrder.idPublic)
      );
    },
    rowSelected(rowData) {
      if (this.actionMode !== "") {
        this.action(rowData);
      } else {
        this.$refs.progressPrioritizationColumns?.forEach((x) =>
          x.externalRowSelected(rowData.workOrder.idPublic)
        );
      }
    },
    clearSelectedRow(rowData) {
      if (this.actionMode !== "") {
        this.action(rowData);
      }
      this.$refs.progressPrioritizationColumns?.forEach((x) =>
        x.externalClearRowSelected()
      );
    },
    action(rowData) {
      if (rowData.isMaintenance) {
        if (this.actionMode === "start") {
          this.startMaintenance(rowData, false);
        }
        if (this.actionMode === "complete") {
          this.$refs.confirmModal.show().then((response) => {
            if (response == true) {
              this.startMaintenance(rowData, true);
            }
          })
        }
      } else {
        if (this.actionMode === "start") {
          this.startWorkOrderProgress(rowData.workOrderProgress);
        }
        if (this.actionMode === "complete") {
          this.$refs.confirmModal.show().then((response) => {
            if (response == true) {
              this.openCompleteStepsBeforeModal(
                rowData.workOrder,
                rowData.workOrderProgress
              );
            }
          })
        }
      }
    },
    startMaintenance(maintenance, complete) {
      this.isLoading = true;
      let promise;
      if (maintenance.isMerged) {
        promise = CalendarApiService.startMergedProgressMaintenance(
          maintenance.maintenanceId,
          { complete: complete }
        );
      } else {
        promise = CalendarApiService.startProgressMaintenance(
          maintenance.maintenanceId,
          {
            complete: complete,
          }
        );
      }
      promise
        .then(() => {
          let column = this.$refs.progressPrioritizationColumns.find(
            (x) =>
              x.progressPrioritization.maintenances.find(
                (m) => m.id === maintenance.maintenanceId
              ) != null
          );
          column.loadData(true);
          this.actionMode = "";
          this.$refs.ongoingMaintenancesColumn.loadData();
        })
        .finally(() => (this.isLoading = false));
    },
    startWorkOrderProgress(workOrderProgress) {
      this.isLoading = true;
      CalendarApiService.startProgressAppointment(
        workOrderProgress.workOrderStepIdPublic
      )
        .then(() => {
          workOrderProgress.dateStarted = this.$dayjs();
          workOrderProgress.status = "Started";
          this.actionMode = "";
        })
        .finally(() => (this.isLoading = false));
    },
    openCompleteStepsBeforeModal(workOrder, workOrderProgress) {
      //get progress config
      this.isLoading = true;
      let progApp = workOrderProgress;
      CalendarApiService.getProgressConfig(workOrderProgress.progress.id).then(
        (response) => {
          this.isLoading = false;
          let config = response.data.data;
          if (
            config.askToCompletePreviousProgress == true &&
            progApp.progress.id != workOrder.nextProgressToComplete.id
          ) {
            this.$refs.completeStepsBeforeModal.show().then(
              (userResponse) => {
                this.completeProgressAppointment(progApp, userResponse);
              },
              () => { }
            );
          } else {
            this.completeProgressAppointment(progApp, true);
          }
        }
      );
    },
    // eslint-disable-next-line no-unused-vars
    completeProgressAppointment(
      progressAppointment,
      completeProgressBefore = false
    ) {
      CalendarApiService.completeProgressAppointment(
        progressAppointment.workOrderStepIdPublic,
        completeProgressBefore
      ).then(() => {
        this.actionMode = "";
        let notMerge = (x, progressAppointment, completeProgressBefore) => {
          let isOrderSmaller =
            x.progress?.order <= progressAppointment.progress.order;
          let isSameProgress = x.progress.id == progressAppointment.progress.id;
          return completeProgressBefore ? isOrderSmaller : isSameProgress;
        };
        let merge = (x, progressAppointment, completeProgressBefore) => {
          let isOrderSmaller =
            x.mergedProgress.find((p) =>
              completeProgressBefore
                ? p.order <= progressAppointment.progress.order
                : p.id === progressAppointment.progress.id
            ) != null;
          return isOrderSmaller;
        };
        var updatedProgressPrioritized =
          this.$refs.progressPrioritizationColumns.filter(
            (x) =>
              (!x.progressPrioritization.merged &&
                notMerge(
                  x.progressPrioritization,
                  progressAppointment,
                  completeProgressBefore
                )) ||
              (x.progressPrioritization.merged &&
                merge(
                  x.progressPrioritization,
                  progressAppointment,
                  completeProgressBefore
                ))
          );
        updatedProgressPrioritized.forEach((x) => x.loadData(true));
      });
    },
    changeActionMode(actionMode) {
      if (actionMode === this.actionMode) {
        this.actionMode = "";
      } else {
        this.actionMode = actionMode;
      }
    },
    getVariantMode(actionMode) {
      if (actionMode === "start") {
        if (this.actionMode === "start") {
          return "primary";
        } else {
          return "outline-primary";
        }
      }
      if (actionMode === "complete") {
        if (this.actionMode === "complete") {
          return "success";
        } else {
          return "outline-success";
        }
      }
    },
  },
  updated() {
      this.$refs.progressPrioritizationColumns?.forEach(x => x.resizeUpdate());
  }
};
</script>

<template>
  <div style="
      display: flex;
      flex-direction: column;
      flex: 1 1 1px;
      position: relative;
    " class="progressPriotization">
    <div style="
        display: flex;
        align-items: center;
        position: relative;
        justify-content: flex-end;
        position: absolute;
        right: 5px;
        top: -60px;
      ">
      <div class="row m-2 justify-content-center align-items-center">
        <ProgressPrioritizationSelector v-model="progressPrioritizations"
          @toggle-ongoingmaintenances="setOngoingMaintenanceVisibility" />
      </div>
    </div>
    <div class="card card-body p-1 mb-2 boxShadow" style="display: flex; flex-direction: column">
      <div class="row m-2 pt-2 justify-content-end align-items-center">
        <ThresholdFilter v-model="thresholdsFilter" />
        <b-form-checkbox class="mr-3" size="lg" switch v-model="showInfo" @change="changeInfo"><span
            style="white-space: nowrap">{{
              $t("general.expand")
            }}</span></b-form-checkbox>
        <b-button 
          @click="changeActionMode('start')" 
          :class="{
            'mr-2': true,
            'btn-outline-primary': actionMode != 'start',
            'btn-primary': actionMode == 'start',
          }" 
          size="md" 
          :variant="getVariantMode('start')"
        >
          {{ $t("general.start") }}
        </b-button>
        <b-button @click="changeActionMode('complete')" class="mr-2" size="md" :variant="getVariantMode('complete')">
          {{ $t("general.complete") }}
        </b-button>
      </div>
      <div id="progressSchedulingContent" class="pt-2 row no-gutters d-flex flex-row" style="
          position: relative;
          scrollbar-width: thin;
          scrollbar-color: #dee0e3 transparent;
          flex-wrap: nowrap;
          flex: 1 1 1px;
          min-height: 0;
          overflow: auto;
        ">
        <loading :active.sync="isLoading" :can-cancel="false" color="#f1b44c" :opacity="0" :width="70" :height="70"
          loader="dots" :is-full-page="false">
        </loading>
        <ProgressPrioritizationColumn v-for="(ps, index) in progressPrioritizations" ref="progressPrioritizationColumns"
          v-model="progressPrioritizations[index]" :key="(ps.merged ? 'm' : '') + ps.id"
          :thresholdsConfig="thresholdsConfig" :thresholdsFilter="thresholdsFilter" :noteModal="$refs.noteModal"
          :hideModal="$refs.hideModal" :positionModal="$refs.selectLockPositionModal" @row-selected="rowSelected"
          @clear-selected-row="clearSelectedRow">
        </ProgressPrioritizationColumn>
        <OngoingMaintenancesColumn ref="ongoingMaintenancesColumn" v-if="ongoingMaintenancesVisible" />
      </div>
    </div>
    <CompleteStepsBeforeModal ref="completeStepsBeforeModal"></CompleteStepsBeforeModal>
    <NoteModal ref="noteModal" />
    <LockPositionSelectorModal ref="selectLockPositionModal" />
    <HideRowConfirmationModal ref="hideModal" />
    <ConfirmModal ref="confirmModal"/>
  </div>
</template>

