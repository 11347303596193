<script>
/**
 * Dashboard Component
 */
export default {
  props: {},
  components: {},
  data() {
    return {
      show: false,
      selectLockPositionInput: null,
      confirm: null,
      close: null,
    };
  },
  created() {},
  beforeDestroy() {},
  mounted() {},
  methods: {
    showModal(selectLockPosition) {
      this.selectLockPositionInput = selectLockPosition;
      this.show = true;
      return new Promise((resolve, reject) => {
        this.$refs.selectLockPositionModal.onOk = () => {
          resolve(this.selectLockPositionInput);
        };
        this.close = () => {
          reject();
        };
      }).finally(() => {
        (this.selectLockPositionInput = null), (this.show = false);
      });
    },
  },
  computed: {
    selectLockPositionTitle() {
      return this.$t("general.selectLockPositionTitle");
    },
  }
};
</script>

<template>
      <b-modal v-model="show" :title="selectLockPositionTitle" ref="selectLockPositionModal">
        <b-form-spinbutton
          v-model="selectLockPositionInput"
        ></b-form-spinbutton>
      </b-modal>
</template>
