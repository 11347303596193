export default {
    calculateMaintenances(maintenances, rows, isMerged) {
        if (!maintenances || maintenances.length === 0) {
            return rows;
        }
        let values = [];
        let rowAndMaintenances = [];
        for (var maintenance of maintenances) {
            values[maintenance.id, 0] = maintenance.accumulatedValue;
            values[maintenance.id, 1] = maintenance.accumulatedValue;
            if(maintenance.currentEntry != null) {
                rowAndMaintenances.push({
                    isMaintenance: true,
                    maintenanceId: maintenance.id,
                    maintenanceName: maintenance.name,
                    maintenanceColor: maintenance.colorHexa,
                    isMerged: isMerged,
                    isCurrentEntry: true,
                    startDate: maintenance.currentEntry.startDate,
                    accumulatedValue: values[maintenance.id, 1],
                    interval: maintenance.interval,
                    type: maintenance.measureType,
                });
                values[maintenance.id, 0] = 0;
                values[maintenance.id, 1] = 0;
            }
            if (values[maintenance.id, 0] >= maintenance.interval) {
                rowAndMaintenances.push({
                    isMaintenance: true,
                    maintenanceId: maintenance.id,
                    maintenanceName: maintenance.name,
                    maintenanceColor: maintenance.colorHexa,
                    isMerged: isMerged,
                    isCurrentEntry: false,
                    accumulatedValue: values[maintenance.id, 1],
                    interval: maintenance.interval,
                    type: maintenance.measureType,
                });
                values[maintenance.id, 0] -= maintenance.interval;
                values[maintenance.id, 1] = 0;
            }
        }
        rows.forEach((row) => {
            rowAndMaintenances.push(row);
            for (var maintenance of maintenances) {
                values[maintenance.id, 0] += this.getValue(row, maintenance.measureType);
                if (values[maintenance.id, 0] >= maintenance.interval) {
                    rowAndMaintenances.push({
                        isMaintenance: true,
                        maintenanceId: maintenance.id,
                        maintenanceName: maintenance.name,
                        maintenanceColor: maintenance.colorHexa,
                        isMerged: isMerged,
                        isCurrentEntry: false,
                        accumulatedValue: values[maintenance.id, 1],
                        interval: maintenance.interval,
                        type: maintenance.measureType,
                    });
                    values[maintenance.id, 0] -= maintenance.interval;
                    values[maintenance.id, 1] = 0;
                }
            }
        });
        return rowAndMaintenances;
    },
    getValue(row, valueType) {
        if (!valueType) {
            return 1;
        }
        return row.workOrderProgress.values.find(x => x.measureType === valueType)?.value ?? 0;
    },
}