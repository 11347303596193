<script>
/**
 * Dashboard Component
 */
export default {
  props: {},
  components: {},
  data() {
    return {
      show: false,
      confirm: null,
      close: null,
    };
  },
  created() {},
  beforeDestroy() {},
  mounted() {},
  methods: {
    showModal() {
      this.show = true;
      return new Promise((resolve, reject) => {
        this.confirm = () => {
          resolve();
        };
        this.close = () => {
          reject();
        };
      }).finally(() => {
        this.show = false;
      });
    },
  },
  computed: {
    hideWorkOrderFromProgressScheduling() {
      return this.$t("general.hideWorkOrderFromProgressSchedulingTitle");
    },
  },
};
</script>

<template>
  <b-modal
    v-model="show"
    :title="hideWorkOrderFromProgressScheduling"
    style="position: relative"
  >
    <p>{{ $t("general.areYouSureWorkOrderFromProgressScheduling") }}</p>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="danger" class="float-right" @click="confirm">
          {{ $t("general.hide") }}
        </b-button>
        <b-button class="mr-2 float-right" @click="close">
          {{ $t("general.close") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
