var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-title mb-0 pt-3 pb-2 pr-3 pl-3",staticStyle:{"border-top-left-radius":"0.25rem","border-top-right-radius":"0.25rem"},style:({
    background: _vm.getHeaderBackgroundColor(),
  })},[_c('div',{staticClass:"mb-2",staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"mr-3",staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap","font-size":"1.1em","color":"white"}},[(_vm.config.progressPrioritization.merged == false)?_c('div',{attrs:{"id":'id' + _vm.config.progressPrioritization.progress.id}},[_vm._v(" "+_vm._s(_vm.config.progressPrioritization.progress.name)+" ")]):_c('div',{attrs:{"id":'id' + _vm.config.progressPrioritization.id}},[_c('i',{staticClass:"mdi mdi-merge mr-2"}),_vm._v(_vm._s(_vm.config.progressPrioritization.name)+" ")])]),(_vm.config.progressPrioritization.merged == false)?_c('b-tooltip',{attrs:{"variant":"light","target":'id' + _vm.config.progressPrioritization.progress.id,"title":"step","triggers":"hover"}},[_c('div',[_vm._v(" "+_vm._s(_vm.config.progressPrioritization.progress.name)+" ")])]):_vm._e(),(_vm.config.progressPrioritization.merged == true)?_c('b-tooltip',{attrs:{"variant":"light","target":'id' + _vm.config.progressPrioritization.id,"title":"step","triggers":"hover"}},[_c('div',[_vm._v(" "+_vm._s(_vm.config.progressPrioritization.name)+" ")])]):_vm._e(),_c('div',{staticClass:"refreshBox",staticStyle:{"display":"flex","align-items":"center","margin-left":"auto"}},[_c('i',{staticClass:"mdi mdi-refresh mr-2",staticStyle:{"cursor":"pointer","font-size":"1.3em"},on:{"click":_vm.refresh}}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.timerPaused),expression:"timerPaused"}],staticClass:"mdi mdi-play mr-2",staticStyle:{"cursor":"pointer","font-size":"1.3em"},on:{"click":_vm.playTimer}}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.timerPaused),expression:"!timerPaused"}],staticClass:"mdi mdi-pause mr-2",staticStyle:{"cursor":"pointer","font-size":"1.3em"},on:{"click":_vm.pauseTimer}}),_c('i',{staticClass:"ml-2",class:{
          'fas fa-angle-up': _vm.showInfo,
          'fas fa-angle-down': !_vm.showInfo,
        },staticStyle:{"cursor":"pointer","font-size":"1.7em"},on:{"click":function($event){_vm.showInfo = !_vm.showInfo}}})])],1),(_vm.showInfo)?_c('div',[(_vm.config.progressPrioritization.merged == false)?_c('div',[_c('p',{staticClass:"mb-1",staticStyle:{"color":"white","font-size":"0.9em"}},[_vm._v(" "+_vm._s(_vm.$t("general.attribute"))+": "+_vm._s(_vm.$t( "general." + _vm.config.progressPrioritization.attributeDisplayPerWorkOrder ))+" / "+_vm._s(_vm.getProgressMaxCapacityForAttibute())+" ")])]):_vm._e(),_c('div',[_c('p',{staticClass:"mb-2",staticStyle:{"color":"white","font-size":"0.9em"}},[_vm._v(" "+_vm._s(_vm.$t("general.calculation"))+": "+_vm._s(_vm.getFirstCalculation())+" ")])]),(_vm.config.progressPrioritization.merged == false)?_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_c('b-badge',{staticClass:"mr-2 mb-1",style:({
          background: _vm.getThresholdColorForConfig('regular'),
          border: 0,
        })},[_vm._v(_vm._s(_vm.$t("general.regular"))+": "+_vm._s(_vm.config.thresholdsQuantities.regular))]),_c('b-badge',{staticClass:"mr-2 mb-1",style:({
          background: _vm.getThresholdColorForConfig('attentionRequired'),
          border: 0,
        })},[_vm._v(_vm._s(_vm.$t("general.attentionRequired"))+": "+_vm._s(_vm.config.thresholdsQuantities.attentionRequired))]),_c('b-badge',{staticClass:"mr-2 mb-1",style:({
          background: _vm.getThresholdColorForConfig('urgent'),
          border: 0,
        })},[_vm._v(_vm._s(_vm.$t("general.urgent"))+": "+_vm._s(_vm.config.thresholdsQuantities.urgent))]),_c('b-badge',{staticClass:"mr-2 mb-1",style:({
          background: _vm.getThresholdColorForConfig('late'),
          border: 0,
        })},[_vm._v(_vm._s(_vm.$t("general.late"))+": "+_vm._s(_vm.config.thresholdsQuantities.late))])],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }