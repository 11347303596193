<script>
import CalendarApiService from "@/services/calendarService";
import ThresholdService from "@/services/thresholdservice";
import draggable from "vuedraggable";
import ProgressPrioritizationColumnHeader from "./progress-prioritization-column-header";
import ProgressPrioritizationRow from "./progress-prioritization-row";
import MaintenanceService from "@/services/maintenance-service";

/**
 * Dashboard Component
 */
export default {
  model: {
    prop: "progressPrioritization",
    event: "input",
  },
  props: [
    "progressPrioritization",
    "thresholdsConfig",
    "thresholdsFilter",
    "noteModal",
    "hideModal",
    "positionModal",
  ],
  components: {
    draggable,
    ProgressPrioritizationColumnHeader,
    ProgressPrioritizationRow,
  },
  data() {
    return {
      isLoading: false,
      isLoadingOverlay: false,
      progressPrioritizationRows: [],
      selectedRow: null,
      page: 1,
    };
  },
  watch: {},
  created() {
    this.$bus.$on("locale-updated", this.localeUpdated);
    this.loadData();
  },
  beforeDestroy() {
    this.$bus.$off("locale-updated", this.localeUpdated);
  },
  mounted() {},
  computed: {
    isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    },
    progressPrioritizationAndMaintenanceRows: {
      get(){
        return MaintenanceService.calculateMaintenances(
            this.progressPrioritization.maintenances,
            this.progressPrioritizationRows,
            this.progressPrioritization.merged
          );
      }, 
      set(newValue) {
        this.progressPrioritizationRows = newValue.filter((x) => !x.isMaintenance);
      }
    },
    filteredProgressPrioritizationRows() {
      let filterFct = ThresholdService.getFilterByThreshold(
        this.thresholdsConfig,
        this.thresholdsFilter,
        this.progressPrioritization
      );
      return (
        this.progressPrioritizationAndMaintenanceRows?.filter((x) =>
          filterFct(x)
        ) ?? []
      );
    },
    progressPrioritizationConfig() {
      return {
        progressPrioritization: this.progressPrioritization,
        thresholdsConfig: this.thresholdsConfig,
        thresholdsQuantities: {
          regular: this.getThresholdQty("regular"),
          attentionRequired: this.getThresholdQty("attentionRequired"),
          urgent: this.getThresholdQty("urgent"),
          late: this.getThresholdQty("late"),
        },
      };
    },
  },
  methods: {
    loadData(loadingOverlay) {
      let promise;
      this.isLoadingOverlay = loadingOverlay ?? false;
      this.page = 1;
      if (this.progressPrioritization.merged) {
        promise = this.loadMergedData().then((response) => {
          this.progressPrioritization.maintenances =
            response.data.data.mergedProgressSchedulingConfig.maintenances;
          return response;
        });
      } else {
        promise = this.loadNotMergedData().then((response) => {
          this.progressPrioritization.maintenances =
            response.data.data.progressSchedulingConfig.maintenances;
          return response;
        });
      }
      promise.then((response) => {
        this.id = response.data.data.id;
        this.progressPrioritizationRows =
          response.data.data.workOrdersScheduling;
      });
    },
    loadMoreData() {
      this.page++;
      let promise;
      if (this.progressPrioritization.merged) {
        promise = this.loadMergedData();
      } else {
        promise = this.loadNotMergedData();
      }
      promise.then((response) => {
        this.id = response.data.data.id;
        this.progressPrioritizationRows.push(
          ...response.data.data.workOrdersScheduling
        );
      });
    },
    loadNotMergedData() {
      var today = this.$dayjs().format("YYYY-MM-DD");
      return CalendarApiService.getProgressScheduling(
        this.progressPrioritization.progress.id,
        this.page,
        today
      ).finally(() => {
        this.isLoadingOverlay = false;
        this.isLoading = false;
      });
    },
    loadMergedData() {
      var today = this.$dayjs().format("YYYY-MM-DD");
      return CalendarApiService.getMergedProgressScheduling(
        this.progressPrioritization.id,
        this.page,
        today
      ).finally(() => {
        this.isLoadingOverlay = false;
        this.isLoading = false;
      });
    },
    setShowInfo(value) {
      this.$refs.header.setShowInfo(value);
      this.$refs.rows?.forEach((x) => x.setShowInfo(value));
    },
    localeUpdated() {
      this.$forceUpdate();
    },
    getThresholdQty(type) {
      return ThresholdService.getThresholdQty(
        type,
        this.thresholdsConfig,
        this.progressPrioritization,
        this.progressPrioritizationRows
      );
    },
    infiniteScroll(el) {
      let innerBottom =
        el.srcElement.children[0].getBoundingClientRect().bottom;
      let outerBottom = el.srcElement.getBoundingClientRect().bottom;

      if (innerBottom - 1 < outerBottom && this.isLoading == false) {
        this.isLoading = true;
        this.loadMoreData();
      }
    },
    rowSelected(row) {
      if (this.selectedRow != null && this.selectedRow != row) {
        this.selectedRow.isSelected = false;
      }
      this.selectedRow = row;
    },
    internalRowSelected(row) {
      if (this.selectedRow == row || row.modelData.isMaintenance) {
        this.$emit("clear-selected-row", row.modelData);
      } else {
        this.rowSelected(row);
        this.$emit("row-selected", row.modelData);
      }
    },
    externalRowSelected(idPublic) {
      if (
        this.selectedRow == null ||
        this.selectedRow.modelData.workOrder.idPublic !== idPublic
      ) {
        let row = this.$refs.rows?.find(
          (x) => !x.modelData.isMaintenance && x.modelData.workOrder.idPublic === idPublic
        );
        if (row != undefined) {
          row.isSelected = true;
          this.rowSelected(row);
          var offsetTop = row.$el.offsetTop;
          this.$refs.stepBox.scrollTo(0, offsetTop);
        } else {
          this.externalClearRowSelected();
        }
      }
    },
    externalClearRowSelected() {
      if (this.selectedRow != null) {
        this.selectedRow.isSelected = false;
        this.selectedRow = null;
        this.$refs.stepBox.scrollTo(0, 0);
      }
    },
    openNoteModal(row) {
      this.$refs.header.pauseTimer();
      this.noteModal
        .showModal(row)
        .then(
          (resultNote) => {
            CalendarApiService.saveSchedulingWorkOrderNote(
              row.workOrderProgress.progress,
              row.workOrder.idPublic,
              resultNote
            );
            row.note = resultNote;
          },
          () => {}
        )
        .finally(() => {
          this.$refs.header.playTimer();
        });
    },
    openHideModal(row) {
      this.hideModal.showModal().then(
        () => {
          CalendarApiService.schedulingHideWorkOrderForProgress(
            row.workOrderProgress.progress.id,
            row.workOrder.idPublic
          ).then(() => {
            //remove selected work order scheduling from progress list
            var wosWoIdsPublic = this.progressPrioritizationRows.map((wos) => {
              return wos.workOrder.idPublic;
            });
            var wosIndex = wosWoIdsPublic.indexOf(row.workOrder.idPublic);
            if (wosIndex != -1) {
              this.progressPrioritizationRows.splice(wosIndex, 1);
            }
          });
        },
        () => {}
      );
    },
    lockPositions(ev) {
      if (ev.moved) {
        //new locked position
        var elem = ev.moved.element;
        elem.locked = true;

        if (this.progressPrioritization.merged == false) {
          let model = {
            progressId: this.progressPrioritization.progress.id,
            lockedPositions: [],
          };

          let wos = this.progressPrioritizationRows;
          for (let i = 0; i < wos.length; i++) {
            if (wos[i].locked == false) continue;

            let positionData = {
              workOrderIdPublic: wos[i].workOrder.idPublic,
              position: i + 1,
            };

            model.lockedPositions.push(positionData);
          }

          CalendarApiService.schedulingLockPositions(model);
        } else {
          let model = {
            mergedProgressSchedulingConfigId: this.progressPrioritization.id,
            lockedPositions: [],
          };

          let wos = this.progressPrioritizationRows;
          for (let i = 0; i < wos.length; i++) {
            if (wos[i].locked == false) continue;

            let positionData = {
              workOrderIdPublic: wos[i].workOrder.idPublic,
              progressId: wos[i].workOrderProgress.progress.id,
              lockedPosition: i + 1,
            };

            model.lockedPositions.push(positionData);
          }

          CalendarApiService.schedulingMergedProgressLockPositions(model);
        }
      }
    },
    unlockPosition(modelData, position) {
      this.isLoadingOverlay = true;

      let promise;

      if (this.progressPrioritization.merged == false) {
        let model = {
          position: position + 1,
          workOrderIdPublic: modelData.workOrder.idPublic,
          progressId: modelData.workOrderProgress.progress.id,
        };

        promise = CalendarApiService.schedulingUnlockPosition(model);
      } else {
        let model = {
          mergedProgressSchedulingConfigId: this.progressPrioritization.id,
          workOrderIdPublic: modelData.workOrder.idPublic,
          progressId: modelData.workOrderProgress.progress.id,
        };

        promise =
          CalendarApiService.schedulingMergedProgressUnlockPosition(model);
      }
      promise.then(() => {
        this.loadData();
      });
    },
    lockPosition(index, wos) {
      this.positionModal.showModal(index + 1).then(
        (lockPosition) => {
          wos.locked = true;
          this.progressPrioritizationRows.splice(index, 1);
          this.progressPrioritizationRows.splice(lockPosition - 1, 0, wos);
          if (this.progressPrioritization.merged == false) {
            let model = {
              position: lockPosition,
              workOrderIdPublic: wos.workOrder.idPublic,
              progressId: wos.workOrderProgress.progress.id,
            };
            CalendarApiService.schedulingLockPosition(model);
          } else {
            let model = {
              mergedProgressSchedulingConfigId: this.progressPrioritization.id,
              lockedPosition: lockPosition,
              workOrderIdPublic: wos.workOrder.idPublic,
              progressId: wos.workOrderProgress.progress.id,
            };
            CalendarApiService.schedulingMergedProgressLockedPosition(model);
          }
        },
        () => {}
      );
    },
    resizeUpdate() {
      this.$refs.rows?.forEach(x => x.resizeUpdate());
    }
  },
};
</script>

<template>
  <div
    class="col progressPriotizerBox"
    style="
      min-width: 290px;
      margin: 0 5px;
      flex: 1 1 1px;
      min-height: 0;
      display: flex;
      flex-direction: column;
    "
  >
    <div style="flex: 1; min-height: 0; display: flex">
      <div
        class="card mb-2"
        style="
          padding: 0;
          flex: 1;
          min-height: 0;
          background: #0000000d none repeat scroll 0% 0%;
        "
      >
        <div
          class="card-body"
          style="
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 0;
          "
        >
          <ProgressPrioritizationColumnHeader
            ref="header"
            v-model="progressPrioritizationConfig"
            @refresh="loadData(true)"
          />
          <loading
            :active.sync="isLoadingOverlay"
            :can-cancel="false"
            :opacity="0.3"
            color="#f1b44c"
            :width="40"
            :height="40"
            loader="spinner"
            :is-full-page="false"
          >
          </loading>
          <!-- Remplacer le div par simplebar pour avoir un pretty scroll bar -->
          <!-- simplebar ne permet pas de scroll pendant quon drag un element -->
          <div
            ref="stepBox"
            @scroll="infiniteScroll($event)"
            class="mb-2 mt-2"
            style="
              scrollbar-width: thin;
              scrollbar-color: #dee0e3 transparent;
              overflow-y: auto;
              max-height: 100%;
              padding-right: 3px;
              margin: 0px 5px;
              flex: 1;
              min-height: 0;
            "
          >
            <draggable
              handle=".handle"
              @change="lockPositions($event)"
              v-model="progressPrioritizationAndMaintenanceRows"
              tag="div"
              :disabled="isTouchDevice"
              style="position: relative"
            >
              <ProgressPrioritizationRow
                v-for="(row, index) in filteredProgressPrioritizationRows"
                v-model="filteredProgressPrioritizationRows[index]"
                :key="index"
                :position="index"
                :merged="progressPrioritization.merged"
                :thresholdsConfig="thresholdsConfig"
                @note-opened="openNoteModal"
                @selected="internalRowSelected"
                @hide="openHideModal"
                @unlock="unlockPosition"
                @lock="lockPosition"
                ref="rows"
              />
              <div style="position: relative; width: 100%; height: 100px">
                <loading
                  :active.sync="isLoading"
                  :can-cancel="false"
                  :opacity="0"
                  color="#f1b44c"
                  :width="40"
                  :height="40"
                  loader="spinner"
                  :is-full-page="false"
                >
                </loading>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>