export default {
    getThresholdConfig(thresholdTypeConfig, thresholdsConfig) {
        if (thresholdsConfig == null) {
            return null;
        }

        let thresholdConfig;
        switch (thresholdTypeConfig) {
            case "CriticalRatio":
                thresholdConfig = thresholdsConfig.criticalRatio;
                break;
            case "DeliveryDate":
                thresholdConfig = thresholdsConfig.deliveryDate;
                break;
            case "InstallationDate":
                thresholdConfig = thresholdsConfig.installationDate;
                break;
            case "ProductionDate":
                thresholdConfig = thresholdsConfig.productionDate;
                break;
            case "OperationDate":
                thresholdConfig = thresholdsConfig.operationDate;
                break;
        }

        return thresholdConfig;
    },
    isRegular(value, thresholdConfig) {
        return thresholdConfig.regular != null && value >= thresholdConfig.regular.min && value <= thresholdConfig.regular.max;
    },
    isAttentionRequired(value, thresholdConfig) {
        return thresholdConfig.attentionRequired != null && value >= thresholdConfig.attentionRequired.min && value <= thresholdConfig.attentionRequired.max;
    },
    isUrgent(value, thresholdConfig) {
        return thresholdConfig.urgent != null && value >= thresholdConfig.urgent.min && value <= thresholdConfig.urgent.max;
    },
    isLate(value, thresholdConfig) {
        return thresholdConfig.late != null && value >= thresholdConfig.late.min && value <= thresholdConfig.late.max;
    },
    getConditionFct(type) {
        return type === 'regular'
            ? this.isRegular : type === 'attentionRequired'
                ? this.isAttentionRequired : type === 'urgent'
                    ? this.isUrgent : type === 'late'
                        ? this.isLate : undefined;
    },
    getThresholdColor(workOrderScheduling, thresholdsConfig) {
        var color = "black";
        if (thresholdsConfig == null) {
            return color;
        }

        var value = workOrderScheduling.thresholdDisplayValue;
        var thresholdConfig = this.getThresholdConfig(workOrderScheduling.thresholdDisplayType, thresholdsConfig);


        if (thresholdConfig != null) {
            //REGULAR
            if (this.isRegular(value, thresholdConfig)) {
                color = thresholdConfig.regular.colorHexa
            }

            //ATTENTION REQUIRED
            if (this.isAttentionRequired(value, thresholdConfig)) {
                color = thresholdConfig.attentionRequired.colorHexa;
            }

            //URGENT
            if (this.isUrgent(value, thresholdConfig)) {
                color = thresholdConfig.urgent.colorHexa;
            }

            //LATE
            if (this.isLate(value, thresholdConfig)) {
                color = thresholdConfig.late.colorHexa;
            }
        }

        return color;
    },
    getThresholdQty(type, thresholdsConfig, progressPrioritization, progressPrioritizationRows) {

        if (progressPrioritization.merged == true || thresholdsConfig == null) return 0;

        let selectedThresholdConfig = this.getThresholdConfig(progressPrioritization
            .thresholdDisplayPerWorkOrder, thresholdsConfig);

        let conditionFct = this.getConditionFct(type);

        if (!conditionFct) {
            return 0;
        }

        var qty = 0;
        for (var progressPrioritizationRow of progressPrioritizationRows) {
            if (conditionFct(progressPrioritizationRow.thresholdDisplayValue, selectedThresholdConfig)) {
                qty++;
            }
        }
        return qty;
    },
    getThresholdColorForConfig(thresholdType, thresholdsConfig, progressPrioritizationConfig) {
        let defaultColor = "black";

        if (thresholdsConfig == null) return defaultColor;

        var thresholdConfig = this.getThresholdConfig(progressPrioritizationConfig
            .thresholdDisplayPerWorkOrder, thresholdsConfig);
        var color;

        switch (thresholdType) {
            case "regular":
                color = thresholdConfig.regular.colorHexa;
                break;
            case "attentionRequired":
                color = thresholdConfig.attentionRequired.colorHexa;
                break;
            case "urgent":
                color = thresholdConfig.urgent.colorHexa;
                break;
            case "late":
                color = thresholdConfig.late.colorHexa;
                break;
        }

        return color;
    },
    getFilterByThreshold(thresholdsConfig, filterTypes, progressPrioritization) {
        if(progressPrioritization.merged === false && progressPrioritization.thresholdDisplayPerWorkOrder == null) {
            return () => false;
        }
        let thresholdConfigFct = null;
        if(progressPrioritization.merged === false) {
            let thresholdConfig = !progressPrioritization?.thresholdDisplayPerWorkOrder ? null : this.getThresholdConfig(progressPrioritization
                .thresholdDisplayPerWorkOrder, thresholdsConfig);
            thresholdConfigFct = () => thresholdConfig;
        }
        if(progressPrioritization.merged) {
            thresholdConfigFct = (thresholdDisplayPerWorkOrder) => this.getThresholdConfig(thresholdDisplayPerWorkOrder, thresholdsConfig);
        }
        var conditionFcts = [];
        filterTypes?.forEach(x => {
            let conditionFct = this.getConditionFct(x);
            if (conditionFct) {
                conditionFcts.push(conditionFct);
            }
        });
        if (conditionFcts.length == 0) {
            return () => true;
        }
        return (x) => {
            let iteration = 0;
            while (iteration < conditionFcts.length && !conditionFcts[iteration](x.thresholdDisplayValue, thresholdConfigFct(x.thresholdDisplayType))) {
                iteration++;
            }
            return iteration != conditionFcts.length;
        };
    }
}