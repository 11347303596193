<script>
import ThresholdService from "@/services/thresholdservice";
/**
 * Dashboard Component
 */
let defaultTimerValue = 300;

export default {
  model: {
    prop: "config",
    event: "input",
  },
  props: ["config"],
  components: {},
  data() {
    return {
      defaultTimerValue: defaultTimerValue,
      userTimerValue: null,
      timerValue: defaultTimerValue,
      timerInterval: null,
      timerPaused: false,
      timeoutDebounce: null,
      showInfo: true,
    };
  },
  created() {},
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
  mounted() {
    //decrease timer every second
    this.setTimerInterval();
  },
  methods: {
    setShowInfo(value) {
      this.showInfo = value;
    },
    pauseTimer() {
      this.timerPaused = true;

      clearInterval(this.timerInterval);
    },
    playTimer() {
      this.timerPaused = false;

      this.setTimerInterval();
    },
    getHeaderBackgroundColor() {
      if (this.config.progressPrioritization.merged == false) {
        return this.config.progressPrioritization.progress.colorHexa;
      } else {
        return this.config.progressPrioritization.mergedProgress[0].colorHexa;
      }
    },
    getProgressMaxCapacityForAttibute() {
      var capacity = 0;

      var capacities =
        this.config.progressPrioritization.progress.dailyCapacities;
      if (capacities != null && capacities.length > 0) {
        var attributeValue = capacities.filter(
          (c) =>
            c.measureType ==
            this.config.progressPrioritization.attributeDisplayPerWorkOrder
        );
        if (attributeValue.length > 0) {
          attributeValue = attributeValue[0];
          capacity = attributeValue.max;
        }
      }

      return capacity;
    },
    getFirstCalculation() {
      if (this.config.progressPrioritization.priorityCalculations == 0) {
        return "";
      }
      let copy = this.config.progressPrioritization.priorityCalculations
        .slice()
        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
      return this.$t("general." + copy[0].calculationType);
    },
    getThresholdColorForConfig(thresholdType) {
      return ThresholdService.getThresholdColorForConfig(
        thresholdType,
        this.config.thresholdsConfig,
        this.config.progressPrioritization
      );
    },
    refresh() {
      this.timerValue = this.userTimerValue
        ? this.userTimerValue
        : this.defaultTimerValue;
      this.$emit("refresh", { loadingOverlay: true });
    },
    focusTimerInput() {
      clearInterval(this.timerInterval);
    },
    blurTimerInput() {
      if (!this.timerPaused) {
        this.setTimerInterval();
      }
    },
    setTimerInterval() {
      this.timerInterval = setInterval(() => {
        if (this.timerValue <= 0) {
          this.timerValue = this.userTimerValue
            ? this.userTimerValue
            : this.defaultTimerValue;

          //reload
          this.$emit("refresh", {
            loadingOverlay: false,
          });
        } else {
          this.timerValue -= 1;
        }
      }, 1000);
    },
  },
  computed: {
    timeLeft: {
      get: function () {
        return this.timerValue;
      },
      set: function (value) {
        //debounce sur le input user
        if (this.timeoutDebounce != null) {
          clearTimeout(this.timeoutDebounce);
        }

        this.timeoutDebounce = setTimeout(() => {
          this.userTimerValue = value;
          this.timerValue = value;
        }, 500);
      },
    },
  },
};
</script>

<template>
  <div
    class="card-title mb-0 pt-3 pb-2 pr-3 pl-3"
    :style="{
      background: getHeaderBackgroundColor(),
    }"
    style="border-top-left-radius: 0.25rem; border-top-right-radius: 0.25rem"
  >
    <div class="mb-2" style="display: flex; align-items: center">
      <div
        class="mr-3"
        style="
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 1.1em;
          color: white;
        "
      >
        <div
          v-if="config.progressPrioritization.merged == false"
          :id="'id' + config.progressPrioritization.progress.id"
        >
          {{ config.progressPrioritization.progress.name }}
        </div>
        <div v-else :id="'id' + config.progressPrioritization.id">
          <i class="mdi mdi-merge mr-2"></i
          >{{ config.progressPrioritization.name }}
        </div>
      </div>
      <b-tooltip
        v-if="config.progressPrioritization.merged == false"
        variant="light"
        :target="'id' + config.progressPrioritization.progress.id"
        title="step"
        triggers="hover"
      >
        <div>
          {{ config.progressPrioritization.progress.name }}
        </div>
      </b-tooltip>
      <b-tooltip
        v-if="config.progressPrioritization.merged == true"
        variant="light"
        :target="'id' + config.progressPrioritization.id"
        title="step"
        triggers="hover"
      >
        <div>
          {{ config.progressPrioritization.name }}
        </div>
      </b-tooltip>

      <div
        class="refreshBox"
        style="display: flex; align-items: center; margin-left: auto"
      >
        <i
          @click="refresh"
          class="mdi mdi-refresh mr-2"
          style="cursor: pointer; font-size: 1.3em"
        ></i>
        <i
          v-show="timerPaused"
          @click="playTimer"
          class="mdi mdi-play mr-2"
          style="cursor: pointer; font-size: 1.3em"
        ></i>
        <i
          v-show="!timerPaused"
          @click="pauseTimer"
          class="mdi mdi-pause mr-2"
          style="cursor: pointer; font-size: 1.3em"
        ></i>
        <!-- <b-form-input @focus="focusTimerInput" @blur="blurTimerInput" v-model="timeLeft" style="width:55px;" size="sm" placeholder="30"></b-form-input> -->
        <!-- <label @focus="focusTimerInput" @blur="blurTimerInput" class="mb-0" style="width:55px;" size="sm" placeholder="30">{{timeLeft}}</label> -->

        <i
          @click="showInfo = !showInfo"
          style="cursor: pointer; font-size: 1.7em"
          class="ml-2"
          v-bind:class="{
            'fas fa-angle-up': showInfo,
            'fas fa-angle-down': !showInfo,
          }"
        ></i>
      </div>
    </div>
    <div v-if="showInfo">
      <div v-if="config.progressPrioritization.merged == false">
        <p class="mb-1" style="color: white; font-size: 0.9em">
          {{ $t("general.attribute") }}:
          {{
            $t(
              "general." +
                config.progressPrioritization.attributeDisplayPerWorkOrder
            )
          }}
          / {{ getProgressMaxCapacityForAttibute() }}
        </p>
      </div>
      <div>
        <p class="mb-2" style="color: white; font-size: 0.9em">
          {{ $t("general.calculation") }}:
          {{ getFirstCalculation() }}
        </p>
      </div>
      <div
        v-if="config.progressPrioritization.merged == false"
        style="display: flex; flex-wrap: wrap"
      >
        <b-badge
          class="mr-2 mb-1"
          :style="{
            background: getThresholdColorForConfig('regular'),
            border: 0,
          }"
          >{{ $t("general.regular") }}:
          {{ config.thresholdsQuantities.regular }}</b-badge
        >
        <b-badge
          class="mr-2 mb-1"
          :style="{
            background: getThresholdColorForConfig('attentionRequired'),
            border: 0,
          }"
          >{{ $t("general.attentionRequired") }}:
          {{ config.thresholdsQuantities.attentionRequired }}</b-badge
        >
        <b-badge
          class="mr-2 mb-1"
          :style="{
            background: getThresholdColorForConfig('urgent'),
            border: 0,
          }"
          >{{ $t("general.urgent") }}:
          {{ config.thresholdsQuantities.urgent }}</b-badge
        >
        <b-badge
          class="mr-2 mb-1"
          :style="{
            background: getThresholdColorForConfig('late'),
            border: 0,
          }"
          >{{ $t("general.late") }}:
          {{ config.thresholdsQuantities.late }}</b-badge
        >
      </div>
    </div>
  </div>
</template>
