<script>
import CalendarApiService from "@/services/calendarService";
export default {
  components: {},
  data() {
    return {
      workOrdersSearchList: [],
      isSearchingWorkOrder: false,
      searchActivated: false,
      selectedWorkOrder: null,
    };
  },
  methods: {
    asyncFindWorkOrder(search) {
      if (typeof window.LIT !== "undefined") {
        clearTimeout(window.LIT);
      }
      window.LIT = setTimeout(() => {
        if (search == "" || search == null) {
          return;
        }
        this.isSearchingWorkOrder = true;
        CalendarApiService.findWorkOrderByName(search).then((response) => {
          let workOrders = response.data.data;
          this.workOrdersSearchList = workOrders;
          this.isSearchingWorkOrder = false;
        });
      }, 500);
    },
    searchWorkOrder() {
      if (this.selectedWorkOrder != null) {
        this.searchActivated = false;
        this.$emit("workorder-selected", this.selectedWorkOrder);
      }
    },
    clearSearchWorkOrder() {
      this.selectedWorkOrder = null;
      this.searchActivated = false;
      this.$emit("workorder-selected", null);
    },
    selectWorkOrder(data) {
      this.searchActivated = true;

      this.$emit("workorder-selected", data);
    },
  },
};
</script>
<template>
  <div class="mb-3" style="display: flex; align-items: center">
    <div class="zml-3" style="display: flex">
      <multiselect
        v-model="selectedWorkOrder"
        class="workOrderSearch"
        label="name"
        style="width: 205px; border-top-right-radius: 0"
        :placeholder="$t('general.searchWorkOrder')"
        open-direction="bottom"
        :options="workOrdersSearchList"
        :multiple="false"
        :searchable="true"
        :loading="isSearchingWorkOrder"
        :clear-on-select="true"
        :close-on-select="true"
        :options-limit="30"
        :max-height="600"
        :show-no-results="true"
        :hide-selected="false"
        :show-labels="false"
        @select="selectWorkOrder"
        @search-change="asyncFindWorkOrder"
      >
        <span slot="noResult">No work order found</span>
      </multiselect>
      <b-button
        v-if="!searchActivated"
        @click="searchWorkOrder()"
        class="workOrderSearchButton"
        style="display: flex; align-items: center"
      >
        <i class="bx bx-search"></i>
      </b-button>
      <b-button
        v-if="searchActivated"
        @click="clearSearchWorkOrder()"
        class="workOrderSearchButton"
        style="display: flex; align-items: center"
      >
        <i class="bx bx-x"></i>
      </b-button>
    </div>
  </div>
</template>
